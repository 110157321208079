const tabsAdapter = (data) => {
  return {
    link: data.link,
    text: data.text,
    cssId: data.css_id,
  };
};

export default ({ data }) => {
  return {
    tabs: data?.tabs.map(tabsAdapter),
  };
};
